const HeaderManager = () => {
  const mobileBreakpoint = 900;
  const header = document.querySelector(".cmp-experiencefragment")?.firstElementChild;
  const navigation = document.querySelector(".cmp-navigation")?.firstElementChild;
  const searchContainer = document.querySelector(".search");
  const metaTag = document.querySelector('meta[name="template"]');
  const preContainer = document.querySelector('.container_pre_content');
  const scrollTrigger = 60;

  let mobileHeaderButton;
  let searchHeaderButton;
  let isSearchFocused = false;

  const init = () => {
    addTemplateClass();
    createButtons();
    hideElements();
    addEventListeners();
    checkHeroTeaser();
    navSubMenuFinder();
    addSearchCloseButton();
  };

  const addTemplateClass = () => {
    if (metaTag && metaTag.content) {
      document.body.classList.add(`template-${metaTag.content}`);
    }
  };

  const createButton = (id, ariaLabel, parentElement) => {
    const button = document.createElement("button");
    button.id = id;
    button.setAttribute("aria-label", ariaLabel);
    parentElement?.appendChild(button);
    return button;
  };

  const createButtons = () => {
    mobileHeaderButton = createButton("mobile-header-button", "Toggle Navigation", header);
    searchHeaderButton = createButton("search-header-button", "Toggle Search", navigation);
  };

  const hideElements = () => {
    const elements = document.querySelectorAll(".navigation, .search, .languagenavigation");
    elements.forEach(el => el.classList.add("_js-hide"));
  };

  const toggleMenu = () => {
    header?.classList.toggle("_js-open");
    mobileHeaderButton.classList.toggle("_js-openbtn");
    document.querySelectorAll(".navigation, .search, .languagenavigation").forEach(el => el.classList.toggle("_js-hide"));
    document.body.classList.toggle("_js-overflow-hidden");
    document.body.classList.toggle("_js-main-nav--open");
    searchContainer?.classList.toggle("_js-show");
  };

  const toggleSearch = () => {
    const breadcrumb = document.querySelector('.cmp-experiencefragment--header .breadcrumb');

    searchContainer?.classList.toggle("_js-show");
    document.querySelectorAll('.has-submenu.opened')?.forEach(submenu => submenu.classList.remove('opened'));
    document.querySelector(".cmp-search__input")?.focus();

    // Hide breadcrumb when search is shown
    if (searchContainer?.classList.contains('_js-show')) {
      submenuAndBreadcrumb(searchContainer, true);
    } else {
      submenuAndBreadcrumb(searchContainer, false);
    }
  };

  const addEventListeners = () => {
    mobileHeaderButton.addEventListener("click", toggleMenu);
    searchHeaderButton.addEventListener("click", toggleSearch);

    window.addEventListener('resize', () => {
      if (window.innerWidth > mobileBreakpoint && header?.classList.contains("_js-open")) {
        toggleMenu();
      }
    });

    document.addEventListener('click', hideSearchContainer);

    window.addEventListener('scroll', () => {
      handleScroll();
      adjustLogoAndNavigation();
    });

    // Add focus and blur event listeners to the search input
    const searchInput = document.querySelector(".cmp-search__input");
    searchInput?.addEventListener('focus', () => {
      isSearchFocused = true;
    });
    searchInput?.addEventListener('blur', () => {
      isSearchFocused = false;
    });
  };

  const addSearchCloseButton = () => {
    if (searchContainer) {
      const closeButton = document.createElement('button');
      closeButton.className = 'close-search';
      closeButton.setAttribute('aria-label', 'Close search');
      searchContainer.appendChild(closeButton);

      closeButton.addEventListener('click', (event) => {
        event.stopPropagation();
        searchContainer.classList.remove('_js-show');
      });
    }
  };

  const hideSearchContainer = (event) => {
    // Only hide the search container if it's not focused and the click is outside
    if (!isSearchFocused && !event.target.closest('.cmp-search__input, .cmp-search__results, .cmp-experiencefragment--header, .cmp-search__clear, #search-header-button, #mobile-header-button')) {
      searchContainer?.classList.remove('_js-show');
    }
  };

  const handleScroll = () => {
    const mainHeader = document.querySelector(".main-header");
    if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
      mainHeader?.classList.add("_js-inverted");
      mainHeader?.classList.add("scrolled");
    } else {
      mainHeader?.classList.remove("_js-inverted");
      mainHeader?.classList.remove("scrolled");
    }
  };

  const adjustLogoAndNavigation = () => {
    if (window.innerWidth <= mobileBreakpoint || isSearchFocused) return;
    const logos = document.querySelectorAll('.cmp-experiencefragment--header .image');
    const navigation = document.querySelector('.cmp-experiencefragment--header .navigation');
    const breadcrumb = document.querySelector('.cmp-experiencefragment--header .breadcrumb');
    const elementWithJsShow = document.querySelector('._js-show');

    if (elementWithJsShow) {
      elementWithJsShow.classList.remove('_js-show');
    }

    if (window.scrollY > 300) {
      logos.forEach(logo => logo.classList.add('hidden'));
      navigation.classList.remove('hidden');
    } else if (window.scrollY > 30) {
      logos.forEach(logo => logo.classList.add('hidden'));
      navigation.classList.add('hidden');
      breadcrumb?.classList.add('hidden');
    } else {
      logos.forEach(logo => logo.classList.remove('hidden'));
      navigation.classList.remove('hidden');
      breadcrumb?.classList.remove('hidden');
    }
  };

  const checkHeroTeaser = () => {
    const heroTeaser = preContainer?.getElementsByClassName('teaser--hero');
    if (!heroTeaser || heroTeaser.length === 0) {
      document.querySelector(".main-header")?.classList.add("_js-inverted");
    }
  };

  const navSubMenuFinder = () => {
    const nav = document.querySelector('nav.cmp-navigation > ul');
    nav?.querySelectorAll('li').forEach(li => {
      if (li.querySelector('ul')) {
        li.classList.add('has-submenu');
      }
    });

    addSubMenuListeners();
  };

  const addSubMenuListeners = () => {
    document.querySelectorAll('.cmp-navigation > ul > li.has-submenu').forEach(trigger => {
      const link = trigger.querySelector('a');
      const innerUl = trigger.querySelector('ul');

      // Function to open submenu
      const openSubmenu = () => {
        trigger.classList.add('opened');
        if (window.innerWidth >= mobileBreakpoint) {
          searchContainer?.classList.contains("_js-show") && searchContainer.classList.remove("_js-show");
        }
        submenuAndBreadcrumb(trigger, true);
      };

      // Function to close submenu
      const closeSubmenu = () => {
        trigger.classList.remove('opened');
        submenuAndBreadcrumb(trigger, false);
      };

      // Create and append the arrow element
      const arrowElement = document.createElement('span');
      arrowElement.classList.add('submenu-arrow');
      link.appendChild(arrowElement);

      // Function to update event listeners based on screen size
      const updateEventListeners = () => {
        if (window.innerWidth >= mobileBreakpoint) {
          // Desktop: Use hover
          trigger.addEventListener('mouseenter', openSubmenu);
          trigger.addEventListener('mouseleave', closeSubmenu);

          // Desktop: Remove any existing click listeners to avoid conflicts
          trigger.removeEventListener('click', handleTriggerClick);
        } else {
          // Mobile: Remove hover listeners
          trigger.removeEventListener('mouseenter', openSubmenu);
          trigger.removeEventListener('mouseleave', closeSubmenu);

          // Mobile: Use click
          trigger.addEventListener('click', handleTriggerClick);
        }
      };

      // Function to handle clicks on the trigger element (mobile only)
      const handleTriggerClick = (event) => {
        // Check if the click is directly on the link, not on the submenu
        if (event.target === link) {
          // If the submenu is open, prevent default to keep it open
          if (trigger.classList.contains('opened')) {
            event.preventDefault();
          }
        }
      };

      // Add initial event listeners
      updateEventListeners();

      // Update event listeners on resize
      window.addEventListener('resize', updateEventListeners);

      // Arrow click (all screen sizes)
      arrowElement.addEventListener('click', (event) => {
        event.preventDefault();
        trigger.classList.toggle('opened');
        submenuAndBreadcrumb(trigger, trigger.classList.contains('opened'));
      });

      // Close button for submenu (if needed)
      if (innerUl) {
        const closeButton = document.createElement('button');
        closeButton.className = 'close-submenu';
        closeButton.setAttribute('aria-label', 'Close submenu');
        innerUl.appendChild(closeButton);

        closeButton.addEventListener('click', (event) => {
          event.stopPropagation();
          closeSubmenu();
        });
      }
    });
  };

  // Call the function to initialize the listeners
  addSubMenuListeners();

  const submenuAndBreadcrumb = (trigger, shouldHide) => {
    const header = trigger.closest('.cmp-experiencefragment--header');
    const breadcrumb = header?.querySelector('.breadcrumb');

    if (breadcrumb) {
      header?.classList.toggle('submenu-hide-breadcrumb', shouldHide);
    }
  };

  // Initialize the header manager
  init();
};

export default HeaderManager;