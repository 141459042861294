if (document.readyState === "complete" || document.readyState === "interactive") {
  init();
} else {
  document.addEventListener("DOMContentLoaded", init);
}

function init() {
  // Elements to observe for 'in-view' class toggling
  const elementsToObserve = ['.pc-keynumbers__data', '.teaser--small', '.teaser--medium', '.keynumberitem', '.teaser--big .cmp-teaser__image'];
  const observerOptions = { threshold: 0.4 };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('in-view');
      }
    });
  }, observerOptions);

  elementsToObserve.forEach(selector => {
    const elements = document.querySelectorAll(selector);
    elements.forEach(element => observer.observe(element));
  });

  const keynumbersData = document.querySelector('.pc-keynumbers__data-wrapper');
  const parentContainer = document.querySelector('.pc-keynumbers');

  function applyScrollEffects() {
    if (parentContainer && keynumbersData) {
      const parentHeight = parentContainer.offsetHeight;
      const dataHeight = keynumbersData.offsetHeight;
      const scrollY = window.scrollY || window.pageYOffset;
      const parentTop = parentContainer.offsetTop;
      const maxScrollTop = parentHeight - dataHeight - 160; // Stop 80px before the container's bottom

      // Initial position at the top of the container
      keynumbersData.style.position = 'absolute';

      if (scrollY >= parentTop && scrollY <= parentTop + maxScrollTop) {
        // Calculate offset within the scrollable range
        const offset = scrollY - parentTop;
        keynumbersData.style.top = `${Math.min(offset, maxScrollTop)}px`;
      } else if (scrollY < parentTop) {
        // Stick to the top if scrolling above the container
        keynumbersData.style.top = '0px';
      } else if (scrollY > parentTop + maxScrollTop) {
        // Stop 80px before the bottom of the container
        keynumbersData.style.top = `${maxScrollTop}px`;
      }
    }
  }

  function removeStyles() {
    if (keynumbersData) {
      keynumbersData.removeAttribute("style");
    }
  }

  function handleResize() {
    if (window.innerWidth > 1400) {
      // Add scroll event listener for applyScrollEffects
      window.addEventListener('scroll', applyScrollEffects);
      applyScrollEffects(); // Call the function to apply the effect immediately if needed

      // Handle teaser--big .cmp-teaser__content padding adjustment on scroll
      setupTeaserPaddingAdjustment();
    } else {
      // Remove scroll event listener for applyScrollEffects
      window.removeEventListener('scroll', applyScrollEffects);
      removeStyles();

      // Remove scroll event listener for teaser padding adjustment
      removeTeaserPaddingAdjustment();
    }
  }

  const scrollHandlers = new Map();

  function setupTeaserPaddingAdjustment() {
    const teaserContents = document.querySelectorAll('.teaser--big .cmp-teaser__content');

    teaserContents.forEach((teaserContent) => {
      const adjustPaddingOnScroll = () => {
        // Get current scroll position
        let scrollY = window.scrollY || window.pageYOffset;

        // Get current padding-top value
        let currentPadding = parseInt(window.getComputedStyle(teaserContent).paddingTop, 10) || 0;

        // Reduce padding by 1px for each scroll
        let newPadding = Math.max(24, currentPadding - 1);

        // Apply the new padding value
        teaserContent.style.paddingTop = `${newPadding}px`;
      };

      scrollHandlers.set(teaserContent, adjustPaddingOnScroll);

      const paddingObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Start adjusting padding on scroll when in view
            window.addEventListener('scroll', adjustPaddingOnScroll);
          } else {
            // Stop adjusting padding when out of view
            window.removeEventListener('scroll', adjustPaddingOnScroll);
          }
        });
      }, { threshold: 0.1 });

      paddingObserver.observe(teaserContent);
    });
  }

  function removeTeaserPaddingAdjustment() {
    // Remove the scroll event listener from all teaser elements
    scrollHandlers.forEach((handler, teaserContent) => {
      window.removeEventListener('scroll', handler);
    });
    scrollHandlers.clear();
  }

  // Initial check on page load
  handleResize();

  // Watch for resize events
  window.addEventListener('resize', handleResize);
}