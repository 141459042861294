document.addEventListener('DOMContentLoaded', () => {
  const isPlaying = video => !video.paused && !video.ended && video.readyState > 2;

  const togglePlayPause = (video, button) => {
    video[isPlaying(video) ? 'pause' : 'play']();
    button.classList.toggle('play');
    button.classList.toggle('pause');
  };

  const setupButton = (video, button) => {
    button.classList.add(isPlaying(video) ? 'pause' : 'play');
    button.addEventListener('click', () => togglePlayPause(video, button));
  };

  const createLoader = container => {
    const loader = document.createElement('div');
    loader.className = 'video-progress-loader';
    loader.style.display = 'flex';

    const progressBar = document.createElement('div');
    progressBar.className = 'progress-bar';

    const progress = document.createElement('div');
    progress.className = 'progress';

    const progressText = document.createElement('div');
    progressText.className = 'progress-text';
    progressText.textContent = '0%';

    progressBar.appendChild(progress);
    loader.append(progressBar, progressText);
    container.appendChild(loader);

    return { loader, progress, progressText };
  };

  const initializeVideoControls = () => {
    // Apply loader to ALL videos within '.dynamic-media--video'
    document.querySelectorAll('.dynamic-media--video video').forEach(video => {
      const container = video.closest('.dynamic-media--video');
      if (container) {
        const { loader, progress, progressText } = createLoader(container);

        let progressValue = 0;
        const interval = setInterval(() => {
          // Animation speed
          progressValue += 2;

          // Ensure progressValue doesn't exceed 100
          progressValue = Math.min(progressValue, 100);

          progress.style.width = `${progressValue}%`;
          progressText.textContent = `${progressValue}%`;

          if (progressValue >= 100) {
            clearInterval(interval);

            // Additional handling for videos that take too long:
            setTimeout(() => {
              // Check if video is still not ready
              if (video.readyState < 3) {
                loader.innerHTML = '<p>Taking a bit longer than usual. Please wait...</p>';
              }
            }, 5000);
          }
        }, 30);

        video.addEventListener('canplay', () => {
          clearInterval(interval);
          loader.style.display = 'none';
        });
      }
    });

    // Apply custom controls ONLY to videos within '.videoteaser .dynamic-media--video'
    document.querySelectorAll('.videoteaser .dynamic-media--video video').forEach(video => {
      const container = video.closest('.dynamic-media--video');
      if (container) {
        // Create and append the button
        const button = document.createElement('button');
        button.className = 'custom-video-controls';
        container.appendChild(button);

        setupButton(video, button);

        video.addEventListener('play', () => button.classList.replace('play', 'pause'));
        video.addEventListener('pause', () => button.classList.replace('pause', 'play'));
      }
    });
  };

  const tryInitializeVideoControls = () => {
    if (document.querySelectorAll('.dynamic-media--video video').length === 0) {
      setTimeout(tryInitializeVideoControls, 500);
    } else {
      initializeVideoControls();
    }
  };

  tryInitializeVideoControls();
});
