function highlightTeaserTitles(classPairsArray) {
    // Iterate over each pair of class names provided in the array
    classPairsArray.forEach(([containerClass, titleClass]) => {
        // Get all elements with the container class name
        const elements = document.querySelectorAll(`.${containerClass}`);

        // Iterate over each container element
        elements.forEach(element => {
            // Find the child element with the title class
            const title = element.querySelector(`.${titleClass}`);

            if (title) {
                // Get the text content of the title
                let text = title.innerHTML;

                // Replace '**' pairs with <span> elements and style them (for double asterisks first)
                const formattedTextDouble = text.replace(/\*\*(.*?)\*\*/g, '<span style="color: #326AFA;">$1</span>');

                // Replace '*' pairs with <span> elements and style them (for single asterisks)
                const formattedTextSingle = formattedTextDouble.replace(/\*(.*?)\*/g, '<span style="color: #00BE8C;">$1</span>');

                // Set the updated HTML content back into the title
                title.innerHTML = formattedTextSingle;
            }
        });
    });
}

function teaserManagementAccordion() {
  const teasers = document.querySelectorAll('.teaser--management .cmp-teaser, .teaser--management-extended .cmp-teaser');

  teasers.forEach(teaser => {
    const contentContainer = teaser.querySelector('.cmp-teaser__content');

    if (contentContainer) {
      contentContainer.addEventListener('click', function() {
        const description = teaser.querySelector('.cmp-teaser__description');

        // Close all other teasers
        teasers.forEach(otherTeaser => {
          if (otherTeaser !== teaser) {
            const otherDescription = otherTeaser.querySelector('.cmp-teaser__description');
            const otherContentContainer = otherTeaser.querySelector('.cmp-teaser__content');

            // Check if elements are found
            if (otherDescription && otherContentContainer) {
              if (otherDescription.classList.contains('opened')) {
                otherDescription.classList.remove('opened');
                otherContentContainer.classList.remove('opened');
                otherDescription.style.maxHeight = '0';
              }
            }
          }
        });

        // Toggle the clicked teaser
        if (description.classList.contains('opened')) {
          description.classList.remove('opened');
          contentContainer.classList.remove('opened');
          // Set max-height to 0 for a smoother transition
          description.style.maxHeight = '0';
        } else {
          description.classList.add('opened');
          contentContainer.classList.add('opened');
          // Set max-height to actual scrollHeight to enable transition
          description.style.maxHeight = description.scrollHeight + 'px';
        }
      });
    }
  });
}

function addLineBreaksToTitles(classPairsArray) {
  // Iterate over each pair of class names provided in the array
  classPairsArray.forEach(([containerClass, titleClass]) => {
    // Get all elements with the container class name
    const elements = document.querySelectorAll(`.${containerClass}`);

    // Iterate over each container element
    elements.forEach(element => {
      // Find the child element with the title class
      const title = element.querySelector(`.${titleClass}`);

      if (title) {
        // Get the text content of the title
        let text = title.innerHTML;

        // Replace '///' with a line break
        const formattedText = text.replace(/\/\/\/+/g, '<br>');

        // Set the updated HTML content back into the title
        title.innerHTML = formattedText;
      }
    });
  });
}

const customizeFields = [
  ['teaser', 'cmp-teaser__title'],
  ['title', 'cmp-title__text'],
  ['text', 'cmp-text'],
  ['cmp-accordion__header', 'cmp-accordion__title'],
  ['videoteaser', 'pc-videoteaser__headline'],
  ['videoteaser', 'pc-videoteaser__subline']
];

function init() {
  highlightTeaserTitles(customizeFields);
  addLineBreaksToTitles(customizeFields);
  teaserManagementAccordion();
}

// Check if the document is already loaded or interactive
if (document.readyState === "complete" || document.readyState === "interactive") {
  init();
} else {
  // Wait for the DOMContentLoaded event for other cases
  document.addEventListener("DOMContentLoaded", init);
}
