import Swiper from "../../resources/vendor/swiper-bundle.min";

document.addEventListener('DOMContentLoaded', function() {
  let swiperInstance;

  function initializeSwiper() {
    const specificContainer = document.querySelector('.dmm-container--with-benefits-right');

    if (specificContainer) {
      const teasers = specificContainer.querySelectorAll('.teaser--small-round');

      if (teasers.length > 0 && window.innerWidth < 900) {
        if (!swiperInstance) {
          setupSwiper(specificContainer, teasers);
        } else {
          updateSwiper(specificContainer, teasers);
        }
      } else {
        if (swiperInstance) {
          destroySwiper(specificContainer);
        }
      }
    }
  }

  function setupSwiper(container, teasers) {
    const swiperContainer = document.createElement('div');
    swiperContainer.className = 'swiper-container';

    const swiperWrapper = document.createElement('div');
    swiperWrapper.className = 'swiper-wrapper';

    teasers.forEach(teaser => {
      const slide = document.createElement('div');
      slide.className = 'swiper-slide';
      swiperWrapper.appendChild(slide);
      slide.appendChild(teaser);
    });

    swiperContainer.appendChild(swiperWrapper);

    // Create navigation buttons
    const prevButton = document.createElement('div');
    prevButton.className = 'swiper-button-prev';

    const nextButton = document.createElement('div');
    nextButton.className = 'swiper-button-next';

    // Add navigation buttons to the container
    swiperContainer.appendChild(prevButton);
    swiperContainer.appendChild(nextButton);

    // Insert the container at the beginning of the specific container
    container.insertBefore(swiperContainer, container.firstChild);

    initializeSwiperInstance();
  }

  function initializeSwiperInstance() {
    const slidesPerView = window.innerWidth < 580 ? 1.3 : 2.3;

    swiperInstance = new Swiper('.swiper-container', {
      slidesPerView: slidesPerView,
      spaceBetween: 10,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  function updateSwiper(container, teasers) {
    destroySwiper(container);
    setupSwiper(container, teasers);
  }

  function destroySwiper(container) {
    if (swiperInstance) {
      swiperInstance.destroy(true, true);
      swiperInstance = undefined;

      const swiperWrapper = container.querySelector('.swiper-wrapper');
      const cmpContainer = container.querySelector('.cmp-container');

      if (swiperWrapper && cmpContainer) {
        const slides = swiperWrapper.querySelectorAll('.swiper-slide');
        slides.forEach(slide => {
          const teaser = slide.firstElementChild;
          if (teaser) {
            cmpContainer.appendChild(teaser);
          }
        });
      }

      const swiperContainer = container.querySelector('.swiper-container');
      if (swiperContainer) {
        swiperContainer.remove();
      }
    }
  }

  // Initialize Swiper on load
  initializeSwiper();

  // Re-initialize Swiper on resize
  window.addEventListener('resize', initializeSwiper);
});
