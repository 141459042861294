
// Stylesheets
import "./theme.scss";
import "./resources/images/favicon.png"

// Component Javascript
import HeaderManager from "./components/experiencefragment/experiencefragment_header.js";
import "./components/experiencefragment/change_flag.js";
import "./components/form-options/form_dropdown.js";
import "./components/progressbar/progressbar.js";
import "./components/teaser/teaser.js";
import "./components/animation/animation.js";
import "./components/video/video.js";
import "./components/teaser/benefits-teasers";

const documentReadyFunctions = () => {
    HeaderManager();
};

const runDocumentReadyFunctions = () => {
    if (
        document.readyState === "complete" ||
        document.readyState === "interactive"
    ) {
        documentReadyFunctions();
    } else {
        requestAnimationFrame(runDocumentReadyFunctions);
    }
};

runDocumentReadyFunctions();